import React from "react";
import styled from "styled-components";
import { Modal, Frame } from "@react95/core";
import Item from "./Item";
import award from "../assets/explorer_101.ico";

const FilesWrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
`;

const CustomIcon = styled.img`
  width: 16px; // Or whatever size you need
  height: 16px; // Or whatever size you need
`;

function Explorer({ items, closeExplorer, openNotepad, isMobile }) {
  return (
    <Modal
      icon={<CustomIcon src={award} alt="Explorer Icon" />}
      title="Explorer"
      closeModal={closeExplorer}
      style={{
        left: isMobile ? "5%" : "15%",
        top: "30%",
        width: isMobile ? "90%" : 450,
      }}
      menu={[
        { name: "File", list: [] },
        { name: "Edit", list: [] },
        { name: "Help", list: [] },
      ]}
    >
      <Frame bg="white" boxShadow="in" height="100%">
        <FilesWrapper>
          {items.map((item) => (
            <Item key={item.id} item={item} openNotepad={openNotepad} />
          ))}
        </FilesWrapper>
      </Frame>
    </Modal>
  );
}

export default Explorer;

