import React, { useEffect, useState, useContext } from "react";
import DataContext from "../../contexts/dataContext";
import About from "./About";
import Resume from "./Resume";
import Projects from "./Projects";
import Awards from "./Awards";
import Publications from "./Publications";
// import Skills from './Skills'
import Connect from "./Connect";

function ContentFactory({ id, isMobile }) {
  const data = useContext(DataContext);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const file = data.getItem(id);
    setItem(file);
  }, [id, data]);

  if (item === null) {
    return <div></div>;
  }

  switch (item.id) {
    case "about":
      return <About content={item.content} />;
    case "resume":
      return <Resume content={item.content} />;
    case "projects":
      return <Projects content={item.content} />;
    case "publications":
      return <Publications content={item.content} />;
    case "awards":
      return <Awards content={item.content} />;
    case "connect":
      return <Connect content={item.content} />;
    default:
      return <div></div>;
  }
}

export default ContentFactory;
