import React from "react";
import { Button } from "@react95/core";
import styled from "styled-components";

const StyledLink = styled.a`
  margin-bottom: 10px;
`;

function Publications({ content }) {
  const { currentWork, pastWork, education, resumeLink } = content;

  return (
    <div>
      <h2>Awards</h2>
      <div>
        <p style={{ fontSize: "18px" }}>Eagle Scout with Bronze Palm</p>
        <p style={{ fontSize: "18px" }}>Apple Swift Student Challenge Winner</p>
        <p style={{ fontSize: "18px" }}>UC Davis Engineering Dean Honor List</p>
        <br></br>
      </div>
    </div>
  );
}

export default Publications;
