import React, { useContext } from "react";
import DataContext from "../contexts/dataContext";
import { TaskBar, List } from "@react95/core";
import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;

function Taskbar() {
  return <TaskBar />;
}

export default Taskbar;
