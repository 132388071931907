import React from "react";
export default class DataService {
  _data = {
    items: [
      {
        id: "about",
        name: "About",
        icon: "info_bubble",
        content: {},
      },
      {
        id: "projects",
        name: "Projects",
        icon: "notepad_2",
        content: {},
      },
      {
        id: "resume",
        name: "Research",
        icon: "notepad_2",
        content: {},
      },
      {
        id: "connect",
        name: "Connect",
        icon: "inetcfg_2301",
        content: {},
      },
    ],
  };

  getItems() {
    return this._data.items.map(({ id, name, icon }) => ({ id, name, icon }));
  }

  getItem(id) {
    return this._data.items.find((x) => x.id === id);
  }

  getProjectInfo() {
    return {
      projectRepo: this._data.projectRepo,
      react95Repo: this._data.react95Repo,
    };
  }
}
