import React from "react";
import styled from "styled-components";
import folderIcon from "../assets/explorer_101.ico"; // Adjust the path accordingly
import emailIcon from "../assets/email.ico";
import resume from "../assets/resume.ico";

const StyledShorcut = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

function Shortcuts({ openExplorer }) {
  const openEmail = () =>
    (window.location.href = "mailto:vnshenoy@ucdavis.edu");

  const openResume = () => {
    window.open("/resume.pdf", "_blank"); // Opens the resume PDF in a new tab
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <StyledShorcut onClick={openExplorer}>
        <img
          src={folderIcon}
          alt="Folder"
          style={{ width: "40px", height: "40px" }} // Added margin below the icon
        />{" "}
        {/* Text is now below the icon */}
      </StyledShorcut>
      <div style={{ marginLeft: "15px" }}>Explorer</div>

      <StyledShorcut onClick={openResume}>
        <img
          src={resume}
          alt="Folder"
          style={{ width: "40px", height: "40px" }} // Added margin below the icon
        />{" "}
        {/* Text is now below the icon */}
      </StyledShorcut>
      <div style={{ marginLeft: "15px" }}>Resume</div>
    </div>
  );
}

export default Shortcuts;
