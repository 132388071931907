import React from "react";
import { Modal, Frame } from "@react95/core";
import ContentFactory from "./NotepadContent/ContentFactory";
import styled from "styled-components";
import meIcon from "../assets/me.ico";
import award from "../assets/bulb.ico";
import research from "../assets/computer_find.ico";
import publications from "../assets/file_pencil.ico";
import phone from "../assets/phone.ico";
import brush from "../assets/brush.ico";
import bear from "../assets/bear.ico";

const FilesWrapper = styled.div`
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  flex-wrap: wrap;
`;

const CustomIcon = styled.img`
  width: 16px; // Or whatever size you need
  height: 16px; // Or whatever size you need
`;

function Notepad({ closeNotepad, selectedItem, isMobile }) {
  const iconSrc = (() => {
    switch (selectedItem.name) {
      case "About":
        return bear;
      case "Projects":
        return brush;
      case "Research":
        return research;
      case "Publications":
        return publications;
      case "Awards":
        return award;
      case "Connect":
        return phone;
      default:
        return meIcon;
    }
  })();

  return (
    <Modal
      icon={<CustomIcon src={iconSrc} alt="Explorer Icon" />}
      title={`${selectedItem.name}`}
      closeModal={closeNotepad}
      style={{
        left: isMobile ? "5%" : "50%",
        top: isMobile ? "3%" : "15%",
        width: isMobile ? "90%" : 450,
      }}
      menu={[
        { name: "File", list: [] },
        { name: "Edit", list: [] },
      ]}
    >
      <Frame
        bg="white"
        boxShadow="in"
        height="100%"
        padding={20}
        style={{
          overflowY: "auto",
          maxHeight: "60vh",
        }}
      >
        <ContentFactory id={selectedItem.id} isMobile={isMobile} />
      </Frame>
    </Modal>
  );
}

export default Notepad;

