import React from "react";
import { Button } from "@react95/core";
import styled from "styled-components";

const StyledLink = styled.a`
  margin-bottom: 10px;
`;

function Publications({ content }) {
  return (
    <div>
      <h2>Publications</h2>
      <div>
        <p style={{ fontSize: "14px" }}>
          <b>
            CovXR: Automated Detection of COVID-19 Pneumonia in Chest X-Rays
            through Machine Learning
          </b>
        </p>
        <p style={{ fontSize: "12px" }}>
          <i>
            <b>V Shenoy</b>, S Malik
          </i>
        </p>
        <p style={{ fontSize: "12px" }}>
          IEEE Symposium Series on Computational Intelligence &#183;{" "}
          <a
            className="link"
            href="https://doi.org/10.1109/SSCI50451.2021.9659919"
            target="_blank"
          >
            <b>IEEE</b>
          </a>
        </p>
        <br></br>
      </div>
      <div>
        <p style={{ fontSize: "14px" }}>
          <b>
            Vascular1: Development and Evaluation of a Virtual Reality
            Ultrasound Guided Vascular Access Training Module
          </b>
        </p>
        <p style={{ fontSize: "12px" }}>
          <i>
            <b>V Shenoy</b>, S Khan, E Lee, O Aalami
          </i>
        </p>
        <p style={{ fontSize: "12px" }}>
          IEEE International Conference on Bioinformatics and Biomedicine &#183;{" "}
          <a
            className="link"
            href="https://doi.org/10.1109/BIBM52615.2021.9669886"
            target="_blank"
          >
            <b>IEEE</b>
          </a>
        </p>
        <br></br>
      </div>
      <div>
        <p style={{ fontSize: "14px" }}>
          <b>
            Vascular1: Evaluation of a Virtual Reality Ultrasound Guided Femoral
            Artery Access Training Module
          </b>
        </p>
        <p style={{ fontSize: "12px" }}>
          <i>
            <b>V Shenoy</b>, et al.
          </i>
        </p>
        <p style={{ fontSize: "12px" }}>
          Journal of Vascular Surgery &#183;{" "}
          <a
            className="link"
            href="https://doi.org/10.1016/j.jvs.2021.06.385"
            target="_blank"
          >
            <b>JVS</b>
          </a>
        </p>
        <p style={{ fontSize: "12px" }}>
          <i>
            S Khan, <b>V Shenoy</b>, et al.
          </i>
        </p>
        <p style={{ fontSize: "12px" }}>
          Journal of the American College of Surgeons &#183;{" "}
          <a
            className="link"
            href="http://doi.org/10.1016/j.jamcollsurg.2021.07.679"
            target="_blank"
          >
            <b>JACS</b>
          </a>
        </p>
        <br></br>
      </div>
      <StyledLink
        href="https://scholar.google.com/citations?hl=en&user=iDeVMC0AAAAJ&view_op=list_works&gmla=AOV7GLMzm46WHPevrd8Cy-hdqZgCJp-PjzhRjVb8FJmYgyfsrCF73uhst7mXNHVdHCKbyULc_1gUrvKcghz_8lSd"
        target="_blank"
      >
        <Button style={{ fontSize: "14px" }} className="pointer">
          Google Scholar
        </Button>
      </StyledLink>
    </div>
  );
}

export default Publications;
