import React from "react";
import styled from "styled-components";
import meIcon from "../assets/me.ico";
import award from "../assets/bulb.ico";
import research from "../assets/computer_find.ico";
import publications from "../assets/file_pencil.ico";
import phone from "../assets/phone.ico";
import brush from "../assets/brush.ico";
import bear from "../assets/bear.ico";

const StyledItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 25%;
  padding: 10px 0;
`;

const StyledSpan = styled.span`
  margin-top: 5px;
`;

function Item({ item, openNotepad }) {
  const { name } = item;
  return (
    <StyledItem>
      <img
        src={
          name == "About"
            ? bear
            : name == "Projects"
            ? brush
            : name == "Research"
            ? research
            : name == "Publications"
            ? publications
            : name == "Awards"
            ? award
            : name == "Connect"
            ? phone
            : meIcon
        }
        alt="Folder"
        className="pointer"
        onClick={() => openNotepad(item)}
        style={{ width: "40px", height: "40px", cursor: "pointer" }}
      />
      <StyledSpan>{name}</StyledSpan>
    </StyledItem>
  );
}

export default Item;
