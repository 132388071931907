import React from "react";

function About({ content }) {
  const linkStyle = {
    color: "#010F7A",
    textDecoration: "none",
  };
  return (
    <div>
      <h1>Vishal Shenoy</h1>
      <p>
       I am an undergraduate student majoring in Computer Science at UC Davis.
      </p>
      <p>
        I enjoy building software and working on difficult technical problems
        with a consumer-facing approach.
      </p>
      <p>
        Last summer, I developed software for AI hardware systems at{" "}
        <a href="https://tenstorrent.com" target="_blank" style={linkStyle}>
          Tenstorrent
        </a>
        . Previously, I also built tools for portfolio and talent management at{" "}
        <a href="https://conviction.com" target="_blank" style={linkStyle}>
          Conviction
        </a>
        .
      </p>
    </div>
  );
}

export default About;
