import React from "react";
import styled from "styled-components";
import {
  FaLinkedin,
  FaGithub,
  FaInstagram,
  FaTwitter,
  FaSpotify,
} from "react-icons/fa";
import { Button } from "@react95/core";

const Icons = { FaLinkedin, FaGithub, FaInstagram, FaTwitter, FaSpotify };

const StyledLink = styled.a`
  color: black;
  display: flex;
  align-items: center;
  margin: 0 5px;
  text-decoration: none;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 20px;
  margin-left: -5px;
`;

function Connect({ content }) {
  const { email, emailText } = content;
  const mailto = `mailto:${email}`;
  return (
    <div>
      <h2>Connect</h2>
      <p></p>
      <ButtonRow>
        <StyledLink href="mailto:vnshenoy@ucdavis.edu" target="_blank">
          <Button style={{ fontSize: "14px" }} className="pointer">
            Email
          </Button>
        </StyledLink>
        <StyledLink
          href="https://www.linkedin.com/in/shenoyvishal/"
          target="_blank"
        >
          <Button style={{ fontSize: "14px" }} className="pointer">
            LinkedIn
          </Button>
        </StyledLink>
        <StyledLink href="https://twitter.com/vishalshenoy_" target="_blank">
          <Button style={{ fontSize: "14px" }} className="pointer">
            <span style={{ textDecoration: "line-through" }}>Twitter</span> X
          </Button>
        </StyledLink>
        <StyledLink href="https://github.com/vishalshenoy/" target="_blank">
          <Button style={{ fontSize: "14px" }} className="pointer">
            GitHub
          </Button>
        </StyledLink>
      </ButtonRow>
      <br></br>
    </div>
  );
}

export default Connect;
